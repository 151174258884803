.data-container {
  display: flex;
  box-shadow: 0px 0px 10px 1px rgba(209, 209, 209, 0.4);
  border-radius: 0.5rem;
  padding: 0.5rem;
}

.account-preference-info {
  width: 100%;
  padding: 0.5rem;
  display: flex;
  justify-content: left;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid #008d87;
  background-color: #f6fafa;
}

.data-box {
  border-right: 1px solid rgba(128, 128, 128, 0.137);
}

.data-box-text {
  padding-bottom: 1.5rem;
}

.distributions-date-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  border-bottom: 1px solid #008d87;
}

@media only screen and (max-width: 900px) {
  .data-container {
    display: flex;
    flex-direction: column;
  }

  .data-box {
    border-right: none;
    border-bottom: 1px solid rgba(128, 128, 128, 0.137);
  }

  .data-box-text {
    padding-bottom: 0rem;
  }
}
