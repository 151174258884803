.distributions-supported-container {
  display: flex;
  flex: auto;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  width: 15rem;
  border-right: 1px solid rgba(128, 128, 128, 0.137);
  padding-right: 1rem;
}

.distributions-supported-container:last-child {
  border-right: none;
  padding-right: 1rem;
}

@media only screen and (max-width: 900px) {
  .distributions-supported-container {
    width: 100%;
    border-right: none;
    justify-content: flex-start;
    border-bottom: 1px solid rgba(128, 128, 128, 0.137);
    padding-bottom: 2rem;
    padding-right: 0;
  }

  .distributions-supported-container:last-child {
    border-bottom: none;
  }

  .distributions-image {
    width: 320px;
  }
}
