@media only screen and (min-width: 900px) {
  .cause-container:nth-child(even) {
    border-left: 1px solid rgba(128, 128, 128, 0.137);
  }
}

@media only screen and (max-width: 900px) {
  .cause-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid rgba(128, 128, 128, 0.137);
  }

  .cause-container > * {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.1rem;
  }

  .cause-container:last-child {
    border: None;
  }
}
