.distributions-container {
  width: 100%;
  box-shadow: 0px 0px 10px 1px rgba(209, 209, 209, 0.4);
  padding: 1.55rem;
  box-sizing: border-box;
  padding-bottom: 0;
}

@media only screen and (max-width: 900px) {
  .distributions-container {
    padding: 1rem;
  }
}
